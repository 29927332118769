<template>
  <div>
    <div class="text-h4 mb-8">
      Исключения для капчи
    </div>

    <div v-if="repo.pending">
      Загрузка...
    </div>

    <template v-if="repo.data">

      <div class="mb-4">
        <v-btn @click="create" color="primary">Добавить номер</v-btn>
      </div>

      <div v-if="repo.data.length">
        <div v-for="item in repo.data" :key="item.identifier">
          <v-row>
            <v-col>
              {{ item.identifier }}
            </v-col>
            <v-col>
              <v-btn icon @click="remove.run(item)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>

        </div>
      </div>
      <div v-else>
        Список пуст
      </div>
    </template>

    <error-display
      v-else-if="repo.error"
      :error="repo.error"
    />

    <v-dialog v-if="dialog" :value="true" :max-width="500" @click:outside="cancel">
      <validation-observer v-slot="{ handleSubmit }">
        <validation-provider slim mode="lazy" rules="required" name="identifier" v-slot="{ errors }">
          <v-text-field
            name="phone"
            v-model="model"
            placeholder="Identifier"
            autofocus
            :error-messages="errors[0]"
          />
        </validation-provider>

        <v-row>
          <v-col>
            <v-btn
              @click="handleSubmit(() => save.run())" color="success"
              :disabled="save.pending || !model"
            >
              {{ save.pending ? 'Готово...' : 'Готово' }}
            </v-btn>
          </v-col>
          <v-col align="right">
            <v-btn @click="cancel" :disabled="save.pending">
              Отменить
            </v-btn>
          </v-col>
        </v-row>
      </validation-observer>
    </v-dialog>
  </div>
</template>

<script>
import { createRequest } from '@/utils/requestsFactory'
import api from '@/services/skip-captcha'
import ListEditorLight from '@/components/ListEditor/ListEditorLight.vue'

export default {
  components: {
    ListEditorLight,
  },
  data () {
    return {
      remove: createRequest(async ({ identifier }) => {
        await api.remove(identifier)
        await this.repo.run()
      }),
      save: createRequest(async () => {
        await api.create({ identifier: this.model })
        this.cancel()
        await this.repo.run()
      }),
      repo: createRequest(() => api.getAll(this.$route.query), []),
      model: null,
      dialog: false,
    }
  },
  mounted () {
    return this.repo.run()
  },
  methods: {
    create () {
      this.model = null
      this.$nextTick(() => this.dialog = true)
    },
    cancel () {
      this.model = null
      this.$nextTick(() => this.dialog = false)
    },
  }
}
</script>
