import BaseService from './baseService'
import api from '../utils/api'

class SkipCaptcha extends BaseService {
  get url () {
    return 'skip-captcha-for-identifiers'
  }

  remove (identifier, config) {
    const id = encodeURIComponent(identifier)
    return api.delete(`${this.url}/${id}`, config)
  }
}

export default new SkipCaptcha()

